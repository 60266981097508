import React from "react"
import Layout from "../components/Layout"
import HeroImage from "../components/HeroImage"
import Content from "../components/Content"
import { graphql } from "gatsby"
import Helmet from "react-helmet"

export default function Bilder({ data }) {
  let createMarkup = () => {
    return { __html: data.allWpPage.nodes[0].content }
  }
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {data.allWpPage.nodes[0].title} — Schülerwohnheim Forster GmbH
        </title>
      </Helmet>
      <HeroImage color="#fdbc4f" title={data.allWpPage.nodes[0].title} />
      <Content bgColor="#ffffff">
        <span
          className="content container"
          style={{
            display: "grid",
            gridTemplateColumns: "49% 49%",
            columnGap: "2%",
            rowGap: "15px",
          }}
          dangerouslySetInnerHTML={createMarkup()}
        />
      </Content>
    </Layout>
  )
}

export const query = graphql`
  {
    allWpPage(filter: { id: { eq: "cG9zdDo4NTM=" } }) {
      nodes {
        title
        id
        content
      }
    }
  }
`
